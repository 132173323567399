import React from "react"

import Layout from "../components/layout"
import ImageFromStarterYoungPeopleAtATable from "../components/imageFromStarterYoungPeopleAtATable"
import SEO from "../components/seo"

import LogoAndFeatureHeader from "../components/logoAndFeatureHeader"
import ImageAdminDashboard from "../components/imageAdminDashboard"
import ImagePractitionerInbox from "../components/imagePractitionerInbox"
import ImageSessionalRecord from "../components/imageSessionalRecord"



const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <ImageFromStarterYoungPeopleAtATable />
    <h1>Welcome to GO Results</h1>
    <p>
      GO Results is an interaction-monitoring and -recording tool optimised for
      the specialist education, youth work and care sectors.
    </p>


    <LogoAndFeatureHeader text="Define key metrics, monitor and measure" />
    <ImageAdminDashboard/>
    <p style={{paddingTop:"10px"}}>You can use GO Results to:</p>
    <ul>
      <li>
        Define the behaviours or characteristics that you wish to record for a
        client over time.
      </li>
      <li>
        Assign a work item to a practitioner so that they can record a client's
        response to an interaction.
      </li>
      <li>
        Monitor the outcomes on a dashboard, showing both:
        <ul>
          <li>Patterns across a group of clients.</li>
          <li>A deep dive into a single client's interactions.</li>
        </ul>
      </li>
    </ul>

    <LogoAndFeatureHeader text="Customise" />
<ImagePractitionerInbox/>
<p style={{paddingTop:"10px"}}>
      GO Results can be customised to address the data capture workflows that
      your organisation needs to effective monitor and analyse its outcomes.
    </p>
    <p>
      You can assign actions to practitioners for them to complete a
      record of the interactions and outcomes of each session.
    </p>
    <p>
      These records are linked to the client and can be used to produce a
      profile of their passage through the interactions and model the impact on
      their behaviours.
    </p>
    <p>
      Results can be viewed at a group level on the dashboard, which is the
      entry point for you to drill into more individual data. Group-level
      reporting can be specified so that you can report on a specific project,
      commissioning authority.
    </p>

    <LogoAndFeatureHeader text="Record outcomes" />
    <ImageSessionalRecord/>
    <p style={{paddingTop:"10px"}}>
      A user with the Project Co-ordinator role can manage the clients and
      practitioners for a given project by using sessions.
    </p>
    <p>GO Results lets you schedule clients to practitioners via sessions. </p>
    <p>
      Each practitioner will can be emailed a notification that they must
      complete a sessional record in GO Results upon completion of the session.
      This will enable the client's perspective to be captured.
    </p>
    <p>
      The Project Co-ordinator can monitor how accurately the sessional records are
      being completed.
    </p>
    <p>Missing sessional records will be flagged up and reminders sent.</p>

    <LogoAndFeatureHeader text="Report reliably" />
    <p>You can tell GO Results when you need to produce key reports to stakeholders. </p>
    <p>
    GO Results will produce the reports and let you know when they are available.
    </p>
    <p>
      Like the need to complete sessional records this will be visible in your personal dashboard.
    </p>

    <LogoAndFeatureHeader text="Secure" />
    <p>
      A role-based permissions model allows you to control which members of your
      team can access which client's record, what they can see and what they can do in the
      record.
    </p>
    <p>All data is encrypted in the cloud datastore.</p>
    <p>
      Every action taken on a client record is logged (including viewing the
      record).
    </p>
    <p>
      Data can be pseudonymised where there is a need to share measurements
      without revealing personally identifiable information.
    </p>    

    <LogoAndFeatureHeader text="Timeline" />
    <p>
      GO Results launches in Autumn 2019. The pilot phase has started but it's
      not too late to join the first wave.
       {/* To become a pilot organisation using
      GO Results <Link to="contact">express your interest</Link>. */}
    </p>

    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}></div>
  </Layout>
)

export default IndexPage
