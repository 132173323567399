import React from "react"
import Logo from "../components/imageIcon"
export default props => {
  return (
    <div className="row" style={{paddingBottom: "0.5em" }}>
      <div className="col-2">
        <Logo />
      </div>
      <div
        className="col-10"
        style={{ backgroundColor: "#8ab38a", paddingTop: "4em" }}
      >
        <h4>{props.text}</h4>
      </div>
    </div>
  )
}
